import {Link} from 'react-router-dom';
import AutoTyper from './AutoTyper';
import image from "./blue-computer-and-blackboard.jpg";
import './Home.css';

function Home() {
  return (
    <div className="Home">
    <div className="about-container">
	  <h1>About Our Club</h1>
	  <p>We are a group of students learning computer programming both for its own sake and to practice as a career. We meet regularly to hone our skills, and we work together on larger <Link to="/projects">projects</Link> over the long term.</p>
	  <h1>Join Us</h1>
	  <p>Membership is open to all Christendom students. If you are interested in joining, <a href="https://discord.gg/ArRbFPGShp" target="_blank" rel="noreferrer">message us</a> or come to one of our <a href="https://discord.gg/9BEeBmUNfj" target="_blank" rel="noreferrer">scheduled meetings</a>. Don't know how to code? <a href="https://discord.gg/PywmmuJrMx" target="_blank" rel="noreferrer">We'll teach you</a>!</p>
	  <h1>Request Software</h1>
	  <p>The Coding Club will create software for Christendom students and organizations free of charge. To submit a request or idea, <a href="https://discord.gg/GQmVQZgKSC" target="_blank" rel="noreferrer">click here</a>.</p>
	  <h1>About This Website</h1>
	  <p> We created this website to provide access to the <Link to="/projects">projects</Link> that we make together for Christendom students and anyone else who finds them useful, and to help prospective employers learn about our <Link to="/members">members</Link>.</p>

    </div>
     <div className="recent-and-autotyper">
    <div className="recent-projects">
      <p><a href="https://christendom.dev/cdomtex">Click here</a> to view our most recent project: a streamlined typesetter that turns plain text into fully-formatted academic papers.</p>
    </div>
    <div className="autotyper-image-container">
    <img src={image} />
    <AutoTyper width={50} height={25} deltaTime={30} />
    </div>
    </div>
    </div>  );
}

export default Home;
